import React from 'react';
import PropTypes from 'prop-types';

import SEO from '../../components/SEO';
import Layout from '../../components/Layout';

const PrivacyPolicyData = {
    title: 'Privacy Policy',
    description: 'Privacy policy for Follow Your Senses in Tuscany'
};

export const PrivacyPolicyPageTemplate = () => (
    <section className="section">
        <div className="container">
            <div className="content">
                <h1>{PrivacyPolicyData.description}</h1>
                <li>
                    1. For the Owner of this website, the protection of Users'
                    personal data is of the utmost importance. It makes great
                    efforts to ensure that Users feel safe when entrusting their
                    personal data when using the website.
                </li>
                <li>
                    2. The User is a natural person, legal person or
                    organizational unit without legal personality, which the law
                    grants legal capacity, using electronic services available
                    on the website.
                </li>
                <li>
                    3. This privacy policy explains the rules and scope of
                    processing the User's personal data, his rights, as well as
                    the obligations of the data administrator, and also informs
                    about the use of cookies.
                </li>
                <li>
                    4. The administrator uses the most modern technical measures
                    and organizational solutions that ensure a high level of
                    protection of personal data being processed and protection
                    against access by unauthorized persons.
                </li>
                <li>
                    I. PERSONAL DATA ADMINISTRATOR The administrator of personal
                    data is the Entrepreneur Mrs. Karolina Lenart, running a
                    business under the name: Follow Your Senses K&D Lenart Spółka komandytowa, with
                    its registered office at: Ul.NadSkawa 14, 34-200 Sucha
                    Beskidzka, Poland, NIP: 5521740593 (hereinafter referred to
                    as: "Owner").
                </li>
                <li>
                    II. PURPOSE OF PERSONAL DATA PROCESSING 1. The Administrator
                    processes the User's personal data in order to: providing
                    payment services, customer service 2. This means that these
                    data are needed in particular for 3. The User may also agree
                    to receive information about news and promotions, which will
                    cause the administrator to also process personal data in
                    order to send commercial information to the User, regarding,
                    among others, new products or services, promotions or sales.
                    4. Personal data is also processed as part of the
                    fulfillment of legal obligations incumbent on the data
                    controller and the performance of tasks in the public
                    interest, including to perform tasks related to security and
                    defense or to store tax documentation. 5. Personal data may
                    also be processed for the purposes of direct marketing of
                    products, securing and pursuing claims or protecting against
                    claims of the User or a third party, as well as marketing of
                    services and products of third parties or own marketing,
                    which is not direct marketing.
                </li>
                <li>
                    III. TYPE OF DATA 1. The administrator processes the
                    following personal data, the provision of which is necessary
                    for: a. Data provided by the User optionally: - date of
                    birth; 2. In the event of withdrawal from the contract or
                    acceptance of the complaint, when the refund is made
                    directly to the User's bank account, in order to make the
                    refund, we also process information regarding the bank
                    account number.
                </li>
                <li>
                    IV. LEGAL BASIS FOR PROCESSING PERSONAL DATA 1. Personal
                    data is processed in accordance with the provisions of
                    Regulation (EU) 2016/679 of the European Parliament and of
                    the Council of 27 April 2016 on the protection of
                    individuals with regard to the processing of personal data
                    and on the free movement of such data, and repealing
                    Directive 95/46 /WE (General Data Protection Regulation), OJ
                    L 119, 4.5.2016, p. 1-88, hereinafter referred to as: "GDPR
                    Regulation". 2. The Administrator processes personal data
                    only after obtaining the User's consent. 3. Consent to the
                    processing of personal data is completely voluntary.
                </li>
                <li>
                    V. YOUR RIGHTS 1. The user may at any time request
                    information from the administrator about the scope of
                    personal data processing. 2. The User may at any time
                    request correction or rectification of his personal data. 3.
                    The user may withdraw his consent to the processing of his
                    personal data at any time, without giving any reason. The
                    request not to process data may relate to a specific purpose
                    of processing indicated by the User, e.g. withdrawal of
                    consent to receive commercial information, or apply to all
                    purposes of data processing. Withdrawal of consent for all
                    purposes of processing will result in the User's account
                    being deleted from the website, along with all the User's
                    personal data previously processed by the administrator.
                    Withdrawal of consent will not affect the actions already
                    performed. 4. The User may at any time request, without
                    giving any reason, that the administrator delete his data.
                    The request to delete data will not affect the activities
                    already performed. Deletion of data means simultaneous
                    deletion of the User's account, along with all personal data
                    saved and processed so far by the administrator. 5. The User
                    may at any time object to the processing of personal data,
                    both in terms of all the User's personal data processed by
                    the administrator, as well as only to a limited extent, e.g.
                    as to the processing of data for a specific purpose. The
                    objection will not affect the actions already taken.
                </li>
                <h2>Contact Info</h2>
                <p>Follow Your Senses K&D Lenart Spolka komandytowa</p>
                <p>Ul. Nad Skawa 14</p>
                <p>34-200 Sucha Beskidzka</p>
                <p>Polska</p>
                <p>NIP: 5521740593</p>
                <p>Regon: 540565014</p>
                <p>+48880876413</p>
                <p>+393455809974</p>
            </div>
        </div>
    </section>
);

PrivacyPolicyPageTemplate.propTypes = {
    title: PropTypes.string.isRequired
};

const PrivacyPolicyPage = () => {
    return (
        <Layout>
            <SEO
                title={PrivacyPolicyData.title}
                description={PrivacyPolicyData.description}
            />
            <PrivacyPolicyPageTemplate title={PrivacyPolicyData.title} />
        </Layout>
    );
};

PrivacyPolicyPage.propTypes = {
    data: PropTypes.object.isRequired
};

export default PrivacyPolicyPage;
